<template>
  <div>
    <h2 class="tg-mobile-header-3 md:tg-desktop-header-3">
      {{ $t('houses') }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'Houses'
};
</script>
